<template>
  <div class="story-view">
    <div class="story-view__wrapper">

    </div>
  </div>
</template>

<script>
export default {
  name: 'StoryView',
};
</script>
